import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { fetch } from '~/utils/fetch'

const isMaintenanceMode = () => {
  return Boolean(
    parseInt(
      (typeof window !== 'undefined' && window.ENV?.MAINTENANCE_MODE) ||
        (typeof process !== 'undefined' && process.env.MAINTENANCE_MODE) ||
        '0',
      10
    )
  )
}

const createHttpLink = (request: Request | null) => {
  const fetchWithCookies = (url: Request | string | URL, options: RequestInit | undefined) => {
    if (request && request.headers.get('cookie')) {
      options = options || {}
      options.headers = {
        ...options.headers,
        cookie: request.headers.get('cookie') || ''
      }
    }
    return fetch(url, options)
  }

  return new HttpLink({
    uri: (typeof window !== 'undefined' && window.ENV?.GRAPHQL_URL) || process.env.GRAPHQL_URL || '',
    fetch: fetchWithCookies,
    credentials: 'include'
  })
}

const createAuthLink = (request: Request | null) => {
  return setContext((_, { headers }) => {
    let token = null
    const cookies = request ? request.headers.get('cookie') : typeof document !== 'undefined' ? document.cookie : ''

    if (cookies) {
      const cookieArray = cookies.split('; ')
      for (let cookie of cookieArray) {
        if (cookie.startsWith('keystonejs-session=')) {
          token = cookie.split('=')[1]
          break
        }
      }
    }

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    }
  })
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    })
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`)
  }
})

const maintenanceLink = new ApolloLink((operation, forward) => {
  if (isMaintenanceMode()) {
    return null
  }
  return forward(operation)
})

const logLink = new ApolloLink((operation, forward) => {
  console.log('GraphQL request', operation)
  return forward(operation).map(response => {
    console.log('GraphQL response', response)
    return response
  })
})

export const createApolloClient = (request: Request | null) => {
  const httpLink = createHttpLink(request)
  const authLink = createAuthLink(request)

  return new ApolloClient({
    link: from([maintenanceLink, authLink, errorLink, logLink, httpLink]),
    cache: new InMemoryCache()
  })
}
