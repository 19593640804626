/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { ApolloProvider } from '@apollo/client'
import { useEffect, startTransition, StrictMode } from 'react'
import * as Sentry from '@sentry/remix'
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
import { hydrateRoot } from 'react-dom/client'
import { createApolloClient } from '~/config/apollo'

const apolloClient = createApolloClient(null)

Sentry.init({
  dsn: window?.ENV?.SENTRY_DSN,
  environment: window?.ENV?.SENTRY_ENVIRONMENT,
  tracesSampleRate: 1,

  // This setting controls the rate at which sessions are sampled as soon as the page starts.
  // Have turned this off for now, so we don't use up all of our replays.
  replaysSessionSampleRate: 0,

  // This setting is for the rate at which sessions are sampled after an error
  // occurs. Sentry will buffer the replay and only upload to Sentry if an error
  // has occured.
  replaysOnErrorSampleRate: 1,

  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.remixRouterInstrumentation(useEffect, useLocation, useMatches)
    }),
    new Sentry.Replay()
  ],
  beforeSend: event => {
    const allowedEnvironments = ['production', 'staging']
    const currentEnvironment = window?.ENV?.SENTRY_ENVIRONMENT || 'development'
    if (allowedEnvironments.includes(currentEnvironment)) {
      return event
    }
    return null
  }
})

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <ApolloProvider client={apolloClient}>
        <RemixBrowser />
      </ApolloProvider>
    </StrictMode>
  )
})
